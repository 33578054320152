import { useMemo } from "react";

import { FetchResult, useMutation, useQuery } from "@apollo/client";

import { RetrieveIdVerification } from "./query/verification";
import { FetchLenderQuotes } from "./query/prequal";
import { AdminSubmitLead, GetLeadsByStage } from "./query/leads";

import {
	AdminSubmitLeadMutation,
	AdminSubmitLeadMutationVariables,
	FetchLenderQuotesMutation,
	FetchLenderQuotesMutationVariables,
	FixAssetInput,
	GetLeadsByStageQuery,
	GetLeadsByStageQueryVariables,
	JourneyStage,
	Lead,
	LeadsOrderBy,
	RetrieveIdVerificationMutation,
	RetrieveIdVerificationMutationVariables,
} from "./graphql/types";

export enum Status {
	ALL = "ALL",
	PENDING = "PENDING",
	COMPLETED = "COMPLETED",
}

const getLeadJourney = (status: Status) => {
	switch (status) {
		case Status.COMPLETED:
			return {
				some: { stage: { in: [JourneyStage.Credit] } },
			};
		case Status.ALL:
		default:
			return {
				some: {
					stage: {
						in: [
							JourneyStage.CreditConsentRequested,
							JourneyStage.CreditConsentSmsSent,
							JourneyStage.CreditConsentLinkOpened,
							JourneyStage.CreditConsentAccepted,
							JourneyStage.CreditConsentDeclined,
							JourneyStage.Credit,
						],
					},
				},
			};
	}
};

interface Filter {
	companyId: number;
	status: Status;
	limit?: number;
	offset?: number;
	search?: string;
}

const s = (text?: string) => ({
	includesInsensitive: text || "",
});

export const useFinanceLeads = ({
	companyId,
	status,
	limit,
	offset,
	search,
}: Filter) => {
	const variables: GetLeadsByStageQueryVariables = {
		condition: {
			companyId,
		},
		filter: {
			leadJourneys: getLeadJourney(status),
			leadJourneysExist: true,
			or: [
				{ firstName: s(search) },
				{ lastName: s(search) },
				{ phoneNumber: s(search) },
			],
		},
		first: limit,
		offset: offset,
		orderBy: [LeadsOrderBy.CreatedAtDesc],
	};

	const { data, loading, error } = useQuery<
		GetLeadsByStageQuery,
		GetLeadsByStageQueryVariables
	>(GetLeadsByStage, { variables });

	return {
		data: (data?.leads?.nodes || []) as Lead[],
		count: data?.leads?.totalCount,
		loading,
		error,
	};
};

export interface IRequestLeadActions {
	adminSubmitLead: (
		variables: AdminSubmitLeadMutationVariables,
	) => Promise<FetchResult<AdminSubmitLeadMutation>>;
}

export const useRequestLead = () => {
	const [doAdminSubmitLead] = useMutation<
		AdminSubmitLeadMutation,
		AdminSubmitLeadMutationVariables
	>(AdminSubmitLead, {
		refetchQueries: [GetLeadsByStage],
		context: {
			headers: {
				"x-force-create-lead": "true",
			},
		},
	});

	const actions: IRequestLeadActions = useMemo(
		() => ({
			adminSubmitLead: (variables: AdminSubmitLeadMutationVariables) =>
				doAdminSubmitLead({
					variables,
				}),
		}),
		[doAdminSubmitLead],
	);

	return {
		actions,
	};
};

export interface IRetrieveIdActions {
	retrieveIdVerification: (
		leadId: number,
	) => Promise<FetchResult<RetrieveIdVerificationMutation>>;
}

export const useRetrieveIdVerification = () => {
	const [doRetrieveIdVerification, { data, loading }] = useMutation<
		RetrieveIdVerificationMutation,
		RetrieveIdVerificationMutationVariables
	>(RetrieveIdVerification);

	const actions: IRetrieveIdActions = useMemo(
		() => ({
			retrieveIdVerification: (leadId: number) =>
				doRetrieveIdVerification({
					variables: {
						leadId: leadId,
					},
				}),
		}),
		[doRetrieveIdVerification],
	);

	return {
		state: {
			loading,
			idVerified: data?.retrieveIdVerification?.data,
		},
		actions,
	};
};

export interface IFetchLenderQuotesActions {
	fetchLenderQuotes: (
		leadId: number,
		updatedAsset?: FixAssetInput
	) => Promise<FetchResult<FetchLenderQuotesMutation>>;
}

export const useFetchLenderQuotes = () => {
	const [doFetchLenderQuotes, { data, loading, error }] = useMutation<
		FetchLenderQuotesMutation,
		FetchLenderQuotesMutationVariables
	>(FetchLenderQuotes);

	const actions: IFetchLenderQuotesActions = useMemo(
		() => ({
			fetchLenderQuotes: (leadId: number, updatedAsset?: FixAssetInput) =>
				doFetchLenderQuotes({
					variables: {
						leadId,
						updatedAsset,
					},
				}),
		}),
		[doFetchLenderQuotes],
	);

	return {
		state: {
			loading,
			error,
			lenderQuotes: data?.fetchLenderQuotes?.quotes,
		},
		actions,
	};
};
