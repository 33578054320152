import React, { Fragment, useMemo } from "react";

import Popover from "~/components/Popover";
import { Typography } from "~/components/Typography";

import { customStyles } from "./styles";

import Checkpoint from "~/assets/vectors/checkpoint-icon.svg";

import { JourneyStage, LeadJourney } from "@api/graphql/types";

import { ICheckpoints } from "./types";

const Checkpoints = ({ journeyStages }: ICheckpoints) => {
	const styles = customStyles();

	const formatStepName = (step: string) => {
		switch (step) {
			case JourneyStage.CreditConsentRequested:
				return "Requested";
			case JourneyStage.CreditConsentSmsSent:
				return "Sms Sent";
			case JourneyStage.CreditConsentLinkOpened:
				return "Link Opened";
			case JourneyStage.CreditConsentAccepted:
				return "Accepted";
			case JourneyStage.CreditConsentDeclined:
				return "Declined";
			case JourneyStage.Credit:
				return "Completed";
			default:
				return "";
		}
	};

	const content = useMemo(() => {
		const isJourneyStageCompleted = (journey: string) => {
			const completed = journeyStages.find(
				(j: LeadJourney) => j?.stage === journey,
			);
			if (completed) {
				return true;
			}
			return false;
		};

		const isCreditRejected = (journey?: string) => {
			const rejected = journeyStages.find(
				(j: LeadJourney) =>
					j?.stage === JourneyStage.CreditConsentDeclined,
			);
			if (rejected) {
				return true;
			}
			return false;
		};

		const isAnyNextStepCompleted = (index: number, elements: string[]) => {
			for (let i = index + 1; i < elements.length; i++) {
				if (isJourneyStageCompleted(elements[i])) {
					return true;
				}
			}
			return false;
		};

		const rejectedCredit = isCreditRejected();

		const steps = [
			JourneyStage.CreditConsentRequested,
			JourneyStage.CreditConsentSmsSent,
			JourneyStage.CreditConsentLinkOpened,
			rejectedCredit
				? JourneyStage.CreditConsentDeclined
				: JourneyStage.CreditConsentAccepted,
			JourneyStage.Credit,
		];

		return (
			<div className={styles.checkpointsContainer}>
				{steps.map(
					(step: string, index: number, elements: string[]) => {
						const anyNextStepCompleted = !!isAnyNextStepCompleted(
							index,
							elements,
						);
						const completedJourney = isJourneyStageCompleted(step);

						const renderSteps = () => {
							if (step === JourneyStage.CreditConsentDeclined) {
								return (
									<Typography
										className={styles.creditDeclined}
										variant="caption"
									>
										X
									</Typography>
								);
							}
							if (completedJourney || anyNextStepCompleted) {
								return <Checkpoint />;
							}
							return index + 1;
						};

						const getBgColor = () => {
							if (step === JourneyStage.CreditConsentDeclined) {
								return "#AD0000";
							}
							if (!completedJourney && !anyNextStepCompleted) {
								return "#ADB5BD";
							}
							return "";
						};

						return (
							<Fragment key={step}>
								<span
									style={{ backgroundColor: getBgColor() }}
									className={styles.checkpoint}
								>
									<Popover text={formatStepName(step)}>
										{renderSteps()}
									</Popover>
								</span>
							</Fragment>
						);
					},
				)}
			</div>
		);
	}, [journeyStages, styles.checkpoint, styles.checkpointsContainer]);

	return <div className={styles.checkpointsContainer}>{content}</div>;
};

export default Checkpoints;
