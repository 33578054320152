import React, { Fragment, useState } from "react";

import { navigate } from "@utils/navigate";

import { Typography } from "~/components/Typography";

import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import IconButton from "@material-ui/core/IconButton";
import MoreHorizIcon from "@material-ui/icons/MoreHoriz";

import { useFeedbackContext } from "~/components/Feedback";

import { useStyles } from "./styles";

import { IOptionsProps } from "./types";

const Options: React.FC<IOptionsProps> = ({ leadId }) => {
	const { handleOpenFeedback } = useFeedbackContext();
	const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
	const styles = useStyles();

	const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
		setAnchorEl(e.currentTarget);
	};

	const handleClose =
		(cb?: (e: React.MouseEvent<HTMLElement, MouseEvent>) => void) =>
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			e.preventDefault();
			if (cb) cb(e);
			setAnchorEl(null);
		};
	// const { requestCredit } = { requestCredit: () => { } };
	// const handleSubmit = async () => {
	// 	try {
	// 		leadId && (await requestCredit());
	// 		handleOpenFeedback({
	// 			message: "ID verification request sent successfully!",
	// 			severity: "info",
	// 		});
	// 	} catch (error) {
	// 		console.error(error);
	// 	}
	// };

	const getLeadPath = (id: string) => `/leads/${id}`;

	const handleNavigate = (id: string) => () => {
		navigate(getLeadPath(id), {
			savePath: true,
			state: {
				skipRouteChangeEvent: true,
			},
		});
	};

	return (
		<Fragment>
			<IconButton className={styles.button} onClick={handleMenuClick}>
				<MoreHorizIcon />
			</IconButton>
			<Menu
				keepMounted
				classes={{
					paper: styles.menu,
				}}
				open={!!anchorEl}
				anchorEl={anchorEl}
				onClose={handleClose()}
				getContentAnchorEl={null}
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "right",
				}}
				transformOrigin={{
					vertical: "top",
					horizontal: "right",
				}}
			>
				{/* <MenuItem onClick={handleSubmit}>
					<Typography variant="body1">Resend Request</Typography>
				</MenuItem> */}
				<MenuItem onClick={handleNavigate(leadId)}>
					<Typography variant="body1">View Lead</Typography>
				</MenuItem>
			</Menu>
		</Fragment>
	);
};

export default Options;
