import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => ({
	root: {
		padding: "8px 8px 24px",
		boxShadow: "none",
		maxWidth: 375,
	},
	button: {
		transition: "none",
		"&:disabled": {
			color: "#FFFFFF",
		},
	},
	combinedInputs: {
		display: "flex",
		gap: 4,
		width: "100%",
		[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
			display: "block",
		},
	},
}));
