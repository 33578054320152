import React from "react";

import { useFinanceContext } from "../../context";

import { Search } from "~/components/Search";
import { PrimaryButton } from "~/components/Button/PrimaryButton";

import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";
import { withStyles } from "@material-ui/styles";
import { FormControlLabel, Switch } from "@material-ui/core";

import { IControlBar } from "./types";

import { useStyles } from "./styles";
import ResquestCreditMobile from "../RequestCreditMobile";

export const ControlBar = ({ status, onChangeStatus }: IControlBar) => {
	const styles = useStyles();
	const { showRequestCreditModal } = useFinanceContext();

	const Label = withStyles((theme) => ({
		label: theme.typography.inputLabel,
	}))(FormControlLabel);

	return (
		<Box className={styles.container}>
			<Search />
			<Box className={styles.topSubNavRight}>
				<Label
					control={
						<Switch
							checked={status === "COMPLETED"}
							onChange={onChangeStatus}
						/>
					}
					labelPlacement="start"
					label="Only Completed"
				/>
				<PrimaryButton
					type="submit"
					onClick={() =>
						showRequestCreditModal({ action: "Request Soft-pull" })
					}
				>
					<Typography className={styles.requestCreditButtonText}>
						Request Soft-pull
					</Typography>
				</PrimaryButton>
			</Box>
			<ResquestCreditMobile
				onClick={() =>
					showRequestCreditModal({ action: "Request Soft-pull" })
				}
			/>
		</Box>
	);
};
