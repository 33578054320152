import { makeStyles } from "@material-ui/core/styles";

export const customStyles = makeStyles(() => ({
	root: {
		width: "100%",
		padding: 10,
		"& .MuiSelect-selectMenu": {
			whiteSpace: "normal",
		},
	},
	inputLabel: {
		fontSize: 16,
		marginBottom: 24,
	},
}));
