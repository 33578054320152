import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(() => ({
	button: {
		padding: 0,
	},
	menu: {
		borderRadius: 8,
		padding: 0,
	},
}));
