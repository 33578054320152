import React, { Fragment, useMemo, useState } from "react";

import { useAuth } from "@api/auth";
import { Status, useFinanceLeads } from "@api/finance";
import { ProductType } from "@api/graphql/types";

import { useFinanceContext } from "../context";

import { isProductEnabled } from "@utils/products";

import { ControlBar } from "~/views/credit/_lib/components/ControlBar";
import CreditListTable from "~/views/credit/_lib/components/Table";
import RequestCreditCta from "~/views/credit/_lib/components/RequestCreditCta";
import { CriticalError } from "~/views/credit/_lib/components/CriticalError";
import Pager from "~/views/credit/_lib/components/Pager";

import { useSearch } from "~/components/Search";
import { ProductCtaCard } from "~/components/ProductCta/Card";
import TOSModal from "~/components/TOSModal";

import { Box } from "@material-ui/core";

import { customStyles } from "./styles";

export const PAGE_SIZE = 10;

const CreditDisplay = () => {
	const { search } = useSearch();
	const { selectedCompany } = useAuth();
	const styles = customStyles();

	const { companyId, page, changePage } = useFinanceContext();

	const [status, setStatus] = useState<Status>(Status.ALL);

	const { data, error, loading, count } = useFinanceLeads({
		companyId,
		limit: PAGE_SIZE,
		offset: (page - 1) * PAGE_SIZE,
		search,
		status: status,
	});

	const isCreditToolEnabled = isProductEnabled(
		selectedCompany,
		ProductType.CreditTool,
	);

	const content = useMemo(() => {
		if (!selectedCompany) {
			return (
				<CriticalError
					message="Ooops! No company selected!"
					error={error}
				/>
			);
		}

		if (!isCreditToolEnabled) {
			return (
				<ProductCtaCard
					style={{ marginLeft: 20, marginRight: 20 }}
					topic="ID_TOOL"
				/>
			);
		}

		if (!loading && !data?.length && !search) {
			return (
				<Fragment>
					<RequestCreditCta />
					<TOSModal
						company={selectedCompany}
						topic={ProductType.CreditTool}
						loadingData={loading}
					/>
				</Fragment>
			);
		}

		const handleClick = (event: React.ChangeEvent) => {
			changePage(event, 1);
			if (status === Status.ALL) {
				setStatus(Status.COMPLETED);
			} else {
				setStatus(Status.ALL);
			}
			return null;
		};

		return (
			<Box className={styles.displayContent}>
				<ControlBar status={status} onChangeStatus={handleClick} />
				<CreditListTable data={data} loading={loading} />
				<Pager count={count} limit={PAGE_SIZE} />
				<TOSModal
					company={selectedCompany}
					topic={ProductType.CreditTool}
					loadingData={loading}
				/>
			</Box>
		);
	}, [
		changePage,
		count,
		data,
		error,
		isCreditToolEnabled,
		loading,
		search,
		selectedCompany,
		status,
		styles.displayContent,
	]);
	return <Fragment>{content}</Fragment>;
};

export default CreditDisplay;
