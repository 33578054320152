import React, {
	useContext,
	createContext,
	useEffect,
	useState,
	ChangeEvent,
	useCallback,
} from "react";

import type { FinanceContextType } from "~/views/credit/_lib/types";

import { useIdLeads, Status } from "@api/id";
import { useAuth } from "@api/auth";
import { useViewContext } from "~/state/view";

import RequestCreditForm from "~/views/credit/_lib/forms";

const defaultContext: FinanceContextType = {
	page: 0,
	error: "",
	companyId: 0,
	loading: false,
	changePage: (event, page) => {
		/* dummy Fb */
	},
	showRequestCreditModal: (props) => {
		/* dummy Fb */
	},
	treeCount: 0,
	count: 0,
};

const FinanceContext = createContext<FinanceContextType>(defaultContext);

export const useFinanceContext = () => {
	const context = useContext(FinanceContext);
	if (!context) {
		throw new Error(
			"You cannot use FinanceContext from outside of its Provider",
		);
	}

	return context;
};

const useFinanceContextValue = () => {
	const [companyId, setCompanyId] = useState(0);
	const [page, setPage] = useState(1);
	const { selectedCompany } = useAuth();
	const changePage = (event: ChangeEvent<unknown>, page: number) => {
		setPage(page);
	};

	useEffect(() => {
		selectedCompany && setCompanyId(selectedCompany.id);
	}, [selectedCompany]);

	const { count } = useIdLeads({
		companyId: companyId,
		status: Status.COMPLETED,
	});
	const treesPlanted = count ? Math.floor(count * (3 / 100)) : 0;

	const {
		actions: { openModal: showLayoutModal, closeModal: closeLayoutModal },
	} = useViewContext();

	const showRequestCreditModal = useCallback(
		(props) => {
			showLayoutModal({
				content: (
					<RequestCreditForm
						{...props}
						onComplete={closeLayoutModal}
					/>
				),
				props: {
					title: props.action,
				},
			});
		},
		[showLayoutModal, closeLayoutModal],
	);

	return {
		error: "",
		page,
		companyId: companyId,
		loading: false,
		count: count || 0,
		treeCount: treesPlanted,
		changePage,
		showRequestCreditModal,
	};
};

export const FinanceProvider: React.FC = ({ children }) => {
	const contextValue = useFinanceContextValue();

	return (
		<FinanceContext.Provider value={contextValue}>{children}</FinanceContext.Provider>
	);
};
