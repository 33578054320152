import React from "react";

import FormControl from "@material-ui/core/FormControl";
import Input from "@material-ui/core/Input";
import InputLabel from "@material-ui/core/InputLabel";
import MenuItem from "@material-ui/core/MenuItem";
import Select from "@material-ui/core/Select";

import { customStyles } from "./styles";

import { IProvinceSelectProps } from "./types";
import { Typography } from "~/components/Typography";

export const provinceOptions = [
	"AB",
	"BC",
	"MB",
	"NB",
	"NL",
	"NS",
	"NT",
	"NU",
	"ON",
	"PE",
	"QC",
	"SK",
	"YT",
];

const ProvinceSelect = ({ onChange, province }: IProvinceSelectProps) => {
	const styles = customStyles();

	return (
		<FormControl className={styles.root}>
			<Typography className={styles.inputLabel} variant="caption">
				Province
			</Typography>
			<Select
				labelId="province-label"
				id="province-select"
				name="province"
				onChange={onChange}
				input={<Input />}
				value={province}
				MenuProps={{
					getContentAnchorEl: () => null!,
				}}
			>
				{provinceOptions?.map((province: string) => (
					<MenuItem key={province} value={province}>
						{province}
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

export default ProvinceSelect;
