import React from "react";

import { ViewComponent, createPrivateView } from "~/model/view/private";
import { FinanceProvider } from "./_lib/context";
import CreditDisplay from "./_lib/Display";

export const Credit: ViewComponent = () => {
	return (
		<FinanceProvider>
			<CreditDisplay />
		</FinanceProvider>
	);
};

export default createPrivateView(Credit, {
	title: "Credit",
});
