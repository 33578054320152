import React from "react";

import { useFinanceContext } from "~/views/credit/_lib/context";

import { Box, Typography } from "@material-ui/core";

import { PrimaryButton } from "~/components/Button";

import RequestCreditImg from "~/assets/images/request-credit.png";

import { customStyles } from "./styles";

const RequestCreditCta: React.FC = () => {
	const { showRequestCreditModal } = useFinanceContext();
	const styles = customStyles();

	return (
		<Box className={styles.container}>
			<img src={RequestCreditImg} className={styles.image} />
			<Typography className={styles.requestTitle}>
				No Credit Bureau’s yet!{" "}
			</Typography>
			<Typography className={styles.requestSubtitle}>
				Send out your first Credit request
			</Typography>
			<PrimaryButton
				onClick={() =>
					showRequestCreditModal({ action: "Request Soft-pull" })
				}
			>
				<Typography variant="inherit">Request Credit</Typography>
			</PrimaryButton>
		</Box>
	);
};

export default RequestCreditCta;
