import React, { useMemo, useState } from "react";

import { Input } from "~/components/Base/BaseInputs";
import { BaseForm } from "~/components/Base/BaseForm";
import { Button } from "~/components/Base/BaseActions";

import { getErrorMessage } from "@utils/errors";

import { emailValidator } from "@autocorp/web-core-utils/lib/emailValidator";

import { useStyles } from "./styles";

import type {
	IRequestCreditState,
	IRequestCreditFormProps,
} from "~/views/credit/_lib/types";
import { useRequestLead } from "@api/finance";
import { useAuth } from "@api/auth";
import { useFeedbackContext } from "~/components/Feedback/context";
import { ProductType } from "@api/graphql/types";
import { Box } from "@material-ui/core";
import ProvinceSelect from "@credit/components/ProvinceSelect";

type RequestCreditStateHandlerKeys = keyof IRequestCreditState;
type RequestCreditStateHandlers = Record<
	RequestCreditStateHandlerKeys,
	(val: string) => void
>;

const defaultMessage = `Hey, we need to verify your credit to move forward. Please follow this link to consent to a credit pull. \n**This will NOT impact your credit!`;

const RequestCreditForm: React.FC<IRequestCreditFormProps> = ({
	onComplete,
}) => {
	const { selectedCompany } = useAuth();
	const {
		actions: { adminSubmitLead },
	} = useRequestLead();
	const styles = useStyles();
	const [loading, setLoading] = useState(false);
	const [error, setError] = useState("");

	const [state, setState] = useState({
		province: "ON",
	});

	const handleChangeProvince = (event: React.ChangeEvent<HTMLInputElement>) =>
		setState({ ...state, [event.target.name]: event.target.value });

	const [inputValues, updateValue] = useState<IRequestCreditState>({
		firstName: "MILO",
		lastName: "TESTADD",
		emailAddress: "test@example.com",
		phoneNumber: "(514) 529-1282",
		dateOfBirth: "03/03/1989",
		streetNumber: "731",
		street: "BAY AVE",
		city: "Kelowna",
		postalCode: "V1Y 7K2",
		message: defaultMessage,
	});

	const { handleOpenFeedback } = useFeedbackContext();

	const inputHandler = useMemo<RequestCreditStateHandlers>(() => {
		const scopedHandler =
			(key: RequestCreditStateHandlerKeys) => (val: string) => {
				updateValue((state) => ({
					...state,
					[key]: val,
				}));
			};
		return Object.keys(inputValues).reduce(
			(acc, key) => ({
				...acc,
				[key as RequestCreditStateHandlerKeys]: scopedHandler(
					key as RequestCreditStateHandlerKeys,
				),
			}),
			{} as RequestCreditStateHandlers,
		);
	}, [updateValue, inputValues]);

	const {
		message,
		phoneNumber,
		firstName,
		lastName,
		emailAddress,
		dateOfBirth,
		streetNumber,
		postalCode,
	} = inputValues;

	const handleSubmit = async () => {
		let complete = false;
		setError("");
		if (!selectedCompany) {
			return;
		}
		if (!phoneNumber) {
			return setError("Phone Number is mandatory!");
		}
		if (emailAddress && !emailValidator.test(inputValues.emailAddress)) {
			return setError("Please enter a valid emailAddress address");
		}
		try {
			setLoading(true);
			const leadRequest = await adminSubmitLead({
				companyId: selectedCompany.id,
				productType: ProductType.CreditTool,
				messageOverride: message,
				creditConsentLead: true,
				input: {
					phoneNumber,
					firstName,
					lastName,
					emailAddress,
					dateOfBirth,
					streetNumber,
					provinceCode: state.province,
					postalCode,
				},
			});
			const isDuplicatedRequest =
				leadRequest?.data?.adminSubmitLead?.deduplicated;
			if (isDuplicatedRequest) {
				handleOpenFeedback({
					message: "Already sent request to this phone number",
					severity: "warning",
				});
			} else {
				handleOpenFeedback({
					message: "Request sent successfully!",
					severity: "success",
				});
			}
			complete = true;
			onComplete();
		} catch (error) {
			console.log(error);
			setError(getErrorMessage(error));
		} finally {
			if (!complete) setLoading(false);
		}
	};

	const isCompanyArchived = selectedCompany?.archived;

	return (
		<BaseForm error={error} className={styles.root}>
			<Input
				mandatory
				label="First Name"
				placeholder="First Name"
				update={inputHandler.firstName}
				id={"input-first-name"}
				value={inputValues.firstName}
			/>
			<Input
				mandatory
				label="Last Name"
				placeholder="Last Name"
				update={inputHandler.lastName}
				id={"input-last-name"}
				value={inputValues.lastName}
			/>
			<Input
				mandatory
				label="Date of Birth"
				placeholder="Date of Birth"
				update={inputHandler.dateOfBirth}
				id={"input-date-of-birth"}
				value={inputValues.dateOfBirth}
			/>
			<Input
				label="Email"
				placeholder="Email"
				update={inputHandler.emailAddress}
				id={"input-emailAddress"}
				value={inputValues.emailAddress}
			/>
			<Input
				mandatory
				label="Phone Number"
				placeholder="Phone Number"
				update={inputHandler.phoneNumber}
				id={"input-phone-number"}
				value={inputValues.phoneNumber}
			/>
			<Box className={styles.combinedInputs}>
				<Input
					mandatory
					label="Street Number"
					placeholder="Street Number"
					update={inputHandler.streetNumber}
					id={"input-street-number"}
					value={inputValues.streetNumber}
				/>
				<Input
					mandatory
					label="Street"
					placeholder="Street"
					update={inputHandler.street}
					id={"input-street"}
					value={inputValues.street}
				/>
			</Box>
			<Input
				mandatory
				label="City"
				placeholder="City"
				update={inputHandler.city}
				id={"input-city"}
				value={inputValues.city}
			/>
			<Box className={styles.combinedInputs}>
				<Input
					mandatory
					label="Postal Code"
					placeholder="Postal Code"
					update={inputHandler.postalCode}
					id={"input-postalCode"}
					value={inputValues.postalCode}
				/>
				<ProvinceSelect
					province={state.province}
					onChange={handleChangeProvince}
				/>
			</Box>
			<Input
				label="Message"
				placeholder="Message"
				update={inputHandler.message}
				id={"input-message"}
				value={inputValues.message}
				multiline
			/>
			<Button
				disabled={isCompanyArchived}
				submit
				label={
					isCompanyArchived
						? "Service Unavailable"
						: "SEND REQUEST VIA SMS"
				}
				onClick={handleSubmit}
				loading={loading}
				className={styles.button}
			/>
		</BaseForm>
	);
};

export default RequestCreditForm;
