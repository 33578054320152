import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles(({ breakpoints }) => ({
	root: {
		[breakpoints.up(breakpoints.values.sm)]: {
			display: "none",
		},
	},
	menu: {
		borderRadius: 8,
		padding: 0,
	},
	optionsButton: {
		fontSize: 14,
		lineHeight: 1.5,
		padding: "10px 24px",
	},
}));
