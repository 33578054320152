import { makeStyles } from "@material-ui/core";

export const useStyles = makeStyles((theme) => {
	return {
		container: {
			display: "flex",
			flexFlow: "row nowrap",
			justifyContent: "space-between",
			alignItems: "center",
			[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
				padding: 0,
			},
		},
		topSubNavRight: {
			display: "flex",
			justifyContent: "space-between",
			alignItems: "center",
			gap: 12,
			[theme.breakpoints.down(theme.breakpoints.values.sm)]: {
				display: "none",
			},
		},
		requestCreditButtonText: {
			[theme.breakpoints.down(theme.breakpoints.values.md)]: {
				fontSize: 14,
			},
		},
	};
});
