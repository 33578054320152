import gql from "graphql-tag";

export const FetchLenderQuotes = gql`
	mutation FetchLenderQuotes($leadId: UUID!, $updatedAsset: FixAssetInput) {
		fetchLenderQuotes(input: { leadId: $leadId, updatedAsset: $updatedAsset }) {
			quotes {
				provider
				quoteJson
				id
			}
			success
		}
	}
`;
