import { makeStyles } from "@material-ui/core";

export const customStyles = makeStyles(() => ({
	displayContent: {
		display: "flex",
		flexDirection: "column",
		gap: 24,
		padding: 24,
		"& .MuiTableCell-stickyHeader": {
			border: "none",
			borderBottom: "1px solid #CACACA",
		},
	},
}));
